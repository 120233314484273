import $ from 'cash-dom';
import styles from '../data/map-style.json';

function initializeMap() {
  const $map = $('#map');

  if ($map.length > 0) {
    const center = new google.maps.LatLng(-30.009345, -52.8686587);
    const map = new google.maps.Map($map.get(0), {
      zoom: 15,
      center,
      styles: styles as google.maps.MapTypeStyle[],
      disableDefaultUI: true,
      scrollwheel: false,
    });

    const marker = new google.maps.Marker({
      icon: require('../images/pin-flag.png'),
      position: center,
    });

    marker.setMap(map);
  }
}

window.initializeMap = initializeMap;
