import axios from 'axios';
import swal from 'sweetalert';
import Vue from 'vue';
import qs from 'qs';

export function faleConoscoPage() {
  new Vue({
    data: () => ({
      name: '',
      email: '',
      phone: '',
      phone2: '',
      subject: '',
      message: '',
      visit_date: '',
      visit_people: '',
      submitting: false,
    }),

    methods: {
      async submit() {
        this.submitting = true;

        const formData = {
          name: this.name,
          email: this.email,
          phone: this.phone,
          phone2: this.phone2,
          subject: this.subject,
          message: this.message,
          visit_date: this.visit_date,
          visit_people: this.visit_people,
        };

        try {
          await axios.post<ContactResponse>(
            `${document.baseURI}contato/enviar`,
            qs.stringify(formData),
          );

          swal(
            'Sucesso',
            'Mensagem enviada com sucesso! Responderemos assim que possível.',
            'success',
          );
        } catch (error) {
          if (process.env.NODE_ENV === 'development') {
            console.error(error.response.data);
          }

          swal(
            'Erro',
            'Não foi possível enviar a mensagem. Tente novamente mais tarde.',
            'error',
          );
        } finally {
          this.submitting = false;
        }
      },
    },
  }).$mount('.contact');
}

interface ContactResponse {
  r: true;
}
