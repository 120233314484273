import Vue from 'vue';
import {
  required,
  email as validEmail,
  numeric,
} from 'vuelidate/lib/validators';
import axios from 'axios';
import swal from 'sweetalert';
import { subYears } from 'date-fns';
import { isValidCpf } from '@brazilian-utils/is-valid-cpf';
import qs from 'qs';
import $ from 'cash-dom';
import { beforeDate } from '../validators';

const validationErrorMessages = {
  email: {
    required: 'E-mail é obrigatório',
    validEmail: 'E-mail inválido',
  },
  firstName: {
    required: 'Nome é obrigatório',
  },
  lastName: {
    required: 'Sobrenome é obrigatório',
  },
  birthDate: {
    required: 'Data de nascimento é obrigatória',
    beforeDate: 'Precisa ter no mínimo 18 anos',
  },
  gender: {
    required: 'Sexo é obrigatório',
  },
  phone: {
    required: 'Telefone é obrigatório',
  },
  rg: {
    required: 'RG é obrigatório',
  },
  cpf: {
    required: 'CPF é obrigatório',
    isValidCpf: 'CPF inválido',
  },
  cep: {
    required: 'CEP é obrigatório',
  },
  address: {
    required: 'Endereço é obrigatório',
  },
  addressNumber: {
    required: 'Número é obrigatório',
    numeric: 'Número inválido',
  },
  neighborhood: {
    required: 'Bairro é obrigatório',
  },
  state: {
    required: 'Estado é obrigatório',
  },
  city: {
    required: 'Cidade é obrigatória',
  },
  password: {
    required: 'Senha é obrigatória',
  },
};

export function loginPage() {
  const login = new Vue({
    name: 'LoginForm',

    data: () => ({
      email: '',
      password: '',
      loading: false,
    }),

    validations: {
      email: {
        required,
        validEmail,
      },
      password: {
        required,
      },
    },

    computed: {
      invalid() {
        return this.$v.$invalid;
      },
    },

    methods: {
      async login() {
        this.loading = true;

        try {
          const { data } = await axios.post<LoginResponse>(
            `${document.baseURI}login/auth`,
            qs.stringify({
              email: this.email,
              password: this.password,
            }),
          );

          window.location.href = data.u;
        } catch (error) {
          console.error(error.response);
          const {
            data: { r },
          } = error.response;
          const errorMessages = Object.entries<string>(r);
          let msg = 'Verifique seus dados e tente novamente';
          const field = $(`[name=${errorMessages[0][0]}]`);

          if (errorMessages.length > 0) {
            [[, msg]] = errorMessages;
          }

          await swal('Erro', msg, 'error');

          if (field.length > 0) {
            field.get(0).focus();
          }
        } finally {
          this.loading = false;
        }
      },
    },
  });

  const registration = new Vue({
    name: 'RegisterForm',

    data: () => ({
      cities: window.cities,
      states: window.states,
      loading: false,
      email: '',
      firstName: '',
      lastName: '',
      birthDate: '',
      gender: '',
      phone: '',
      phone2: '',
      rg: '',
      cpf: '',
      cep: '',
      address: '',
      addressNumber: '',
      complement: '',
      neighborhood: '',
      state: '',
      city: '',
      password: '',
    }),

    validations: {
      email: {
        required,
        validEmail,
      },
      firstName: {
        required,
      },
      lastName: {
        required,
      },
      birthDate: {
        required,
        beforeDate: beforeDate(subYears(new Date(), 18)),
      },
      gender: {
        required,
      },
      phone: {
        required,
      },
      rg: {
        required,
      },
      cpf: {
        required,
        isValidCpf,
      },
      cep: {
        required,
      },
      address: {
        required,
      },
      addressNumber: {
        required,
        numeric,
      },
      neighborhood: {
        required,
      },
      state: {
        required,
      },
      city: {
        required,
      },
      password: {
        required,
      },
    },

    computed: {
      invalid() {
        return this.$v.$invalid;
      },

      selectedState() {
        return this.states.find(state => state.id === this.state);
      },

      selectedCity() {
        return this.cities.find(city => city.id === this.city);
      },

      stateCities() {
        if (this.state) {
          return this.cities.filter(city => city.state_id === this.state);
        }
        return [];
      },

      errorMessage() {
        const isError = this.invalid;
        if (isError) {
          // eslint-disable-next-line no-restricted-syntax
          for (const [field, value] of (Object.entries(this.$v) as [
            string,
            { [key: string]: boolean }
          ][]).filter(([key]) => !key.startsWith('$'))) {
            if (value.$invalid) {
              const validations = Object.entries(value).filter(
                ([key]) => !key.startsWith('$'),
              );
              // eslint-disable-next-line no-restricted-syntax
              for (const [validationName, validationResult] of validations) {
                if (!validationResult) {
                  return validationErrorMessages[field][validationName];
                }
              }
            }
          }
        }
        return '';
      },
    },

    methods: {
      async submit() {
        this.loading = true;
        const {
          email,
          firstName,
          lastName,
          birthDate,
          gender,
          phone,
          phone2,
          rg,
          cpf,
          cep,
          address,
          addressNumber,
          complement,
          neighborhood,
          city,
          password,
        } = this;

        try {
          const { data } = await axios.post<LoginResponse>(
            `${document.baseURI}registrar${window.location.search}`,
            qs.stringify({
              email,
              first_name: firstName,
              last_name: lastName,
              birthdate: birthDate,
              gender,
              phone,
              phone2,
              rg,
              cpf,
              cep,
              address,
              number: addressNumber,
              complement,
              neighborhood,
              city,
              password,
            }),
          );

          await swal(
            'Sucesso!',
            'Seu registro foi efetuado com sucesso.',
            'success',
          );

          window.location.href = data.u;
        } catch (error) {
          console.error(error.response);
          const {
            data: { r },
          } = error.response;
          const errorMessages = Object.entries(r);
          let msg = 'Não foi possível realizar o cadastro';
          const field = $(`[name=${errorMessages[0][0]}]`);

          if (errorMessages.length > 0) {
            msg += `: ${errorMessages[0][1]}`;
          }

          await swal('Erro', msg, 'error');

          if (field.length > 0) {
            field.get(0).focus();
          }
        } finally {
          this.loading = false;
        }
      },
    },
  });

  login.$mount('#login-app');
  registration.$mount('#register-app');
}

interface LoginResponse {
  r: string | true;
  /**
   * URL the user should be redirect to upon success
   */
  u: string;
}
