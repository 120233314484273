import $ from 'cash-dom';
import anime from 'animejs';

const $trigger = $('.menu-trigger, #main-menu .close');
const $menu = $('#main-menu');
const $links = $('#main-menu .links li');

async function hideMenu() {
  await anime({
    targets: $menu.get(),
    opacity: 0,
    easing: 'easeInQuad',
    duration: 200,
  }).finished;

  $menu.removeClass('visible');
  $links.css({
    transform: 'translateX(-10%)',
    opacity: 0,
  });
}

async function showMenu() {
  $menu.addClass('visible');

  await anime({
    targets: $menu.get(),
    opacity: 1,
    easing: 'easeOutQuad',
    duration: 200,
  }).finished;

  await anime({
    targets: $links.get(),
    opacity: {
      value: 1,
      easing: 'easeOutQuad',
    },
    translateX: 0,
    delay: anime.stagger(50),
    duration: 250,
    easing: 'easeOutBack',
  }).finished;
}

$trigger.on('click', () => {
  if ($menu.hasClass('visible')) {
    hideMenu();
  } else {
    showMenu();
  }
})
