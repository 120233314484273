import axios from 'axios';
import $ from 'cash-dom';
import swal from 'sweetalert';

export async function minhasComprasPage() {
  const $orders = $('.product-individual');

  if ($orders.length > 0) {
    try {
      const { data: pendingPayment } = await axios.get<PendingPaymentResponse>(
        `${document.baseURI}minhas-compras/pending-payment`,
      );

      if (pendingPayment.r) {
        const isLightboxOpen = window.PagSeguroLightbox(pendingPayment.code!);

        if (!isLightboxOpen) {
          window.location.href = `https://ws.pagseguro.uol.com.br/v2/checkout/payment.html?code=${pendingPayment.code!}`;
        }
      }
    } catch (error) {
      console.error(error.response);
    }
  } else {
    swal({
      text: 'Você ainda não realizou nenhum pedido.',
      title: 'Não há pedidos',
      icon: 'info',
    }).then(() => {
      window.location.href = document.baseURI;
    });
  }
}

interface PendingPaymentResponse {
  r: boolean;
  code?: string;
}
