import Vue from 'vue';
import axios from 'axios';
import '../components/home-products';

const PER_PAGE = 9;

export function ondeEncontrarPage() {
  const app = new Vue<Data, Methods, Computed>({
    data: () => ({
      cities: window.cities,
      states: window.states,
      state: '',
      city: '',
      loading: false,
      locations: [],
      currentPage: 1,
    }),

    computed: {
      selectedState() {
        return this.states.find(state => state.id === this.state);
      },

      selectedCity() {
        return this.cities.find(city => city.id === this.city);
      },

      stateCities() {
        if (this.state) {
          return this.cities.filter(city => city.state_id === this.state);
        }
        return [];
      },

      locationsTitle() {
        let result = '';

        if (this.selectedCity) {
          result = `${this.selectedCity.name} . `;
        }

        if (this.selectedState) {
          result += this.selectedState.abbr;
        }

        return result;
      },

      pageCount() {
        return Math.ceil(this.locations.length / PER_PAGE);
      },

      showPrev() {
        return this.currentPage > 1;
      },

      showNext() {
        return this.currentPage < this.pageCount;
      },

      pageLocations() {
        const skip = (this.currentPage - 1) * PER_PAGE;
        const take = skip + PER_PAGE;
        return (this.locations as Location[]).slice(skip, take);
      },
    },

    methods: {
      async load() {
        this.loading = true;

        const { data } = await axios.get<Location[]>(
          `onde-encontrar/city/${this.city}`,
        );

        this.locations = data.filter(location => !!location) || [];

        this.loading = false;
      },
    },
  });
  app.$mount('#location-list');
}

interface Data {
  cities: City[];
  states: State[];
  state: string;
  city: string;
  loading: boolean;
  locations: Location[];
  currentPage: number;
}

interface Methods {
  load(): Promise<void>;
}

interface Computed {
  selectedState: State | undefined;
  selectedCity: City | undefined;
  stateCities: City[];
  locationsTitle: string;
  pageCount: number;
  showPrev: boolean;
  showNext: boolean;
  pageLocations: Location[];
}

interface Location {
  address: string;
  city: string;
  id: string;
  status: string;
  title: string;
}
