import anime from 'animejs';

const MAX_SCROLL = 200;
let lastUpdate = -1;

const timeline = anime
  .timeline({
    duration: MAX_SCROLL,
    easing: 'easeOutQuad',
    autoplay: false,
  })
  .add({ targets: '#header', background: 'rgba(228, 222, 214, 1)' }, 0)
  .add(
    {
      targets: '#header .logo',
      width: 66,
      height: 79,
      marginTop: 7,
      marginBottom: 7,
    },
    0,
  )
  .add({ targets: '#header .logo h1', opacity: 1 }, 0)
  .add({ targets: '.menu-trigger', color: '#363636' }, 0);

function updateAnimations() {
  const scroll = window.scrollY;

  if (scroll !== lastUpdate && scroll <= MAX_SCROLL) {
    lastUpdate = scroll;
    timeline.seek(scroll);
  }

  requestAnimationFrame(updateAnimations);
}

export function initializeNavbarAnimation() {
  requestAnimationFrame(updateAnimations);
}
