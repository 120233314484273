import { camelCase } from 'lodash';
import * as views from './views';

export function initializeRoute() {
  const [, route] = window.location.pathname
    .replace('/olivas-do-sul', '')
    .split('/');
  const routeName = `${camelCase(route || 'home')}Page`;

  if (routeName in views) {
    views[routeName]();
  }
}
