import Vue from 'vue';
import Vuelidate from 'vuelidate';
import VueTheMask from 'vue-the-mask';

import 'destyle.css/destyle.css';
import 'swiper/dist/css/swiper.css';
import './styles/main.scss';

import './components';
import { initializeRoute } from './router';
import { initializeNavbarAnimation } from './components/navbar';

Vue.use(Vuelidate);
Vue.use(VueTheMask);

initializeRoute();
initializeNavbarAnimation();
