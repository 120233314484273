import Swiper from 'swiper';
import '../components/home-products';

export function homePage() {
  /**
   * Banner
   */
  // eslint-disable-next-line no-new
  new Swiper('.banners .swiper-container', {
    loop: true,
    pagination: {
      el: '.swiper-pagination',
      clickable: true,
    },
    effect: 'fade',
    centeredSlides: true,
    speed: 1000,
    autoplay: {
      delay: 8000,
      disableOnInteraction: false,
    },
    init: true,
  });
}
