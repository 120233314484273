import Swiper from 'swiper';

// eslint-disable-next-line no-new
new Swiper('.products .swiper-container', {
  loop: true,
  navigation: {
    nextEl: '.swiper-button-next',
    prevEl: '.swiper-button-prev',
  },
  centeredSlides: true,
  speed: 1000,
  init: true,
});
